<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/04-3-1banner.png"/>
        <div class="mobile-head-explain" style="margin-top:-30rem">
            <div class="mobile-head-explain-title">定制开发及运维</div>
            <div class="mobile-head-explain-content">凭精湛的研发技术，成熟的项目实施方法论以及快速交付平台，为客户提供各类业务系统的定制化开发服务。</div>
        </div>
        <img class="service-img1" src="../../assets/image/technicalService/04-3-1小图.png"/>
        <div style="margin-top: 1.6rem;">
            <el-collapse-transition>
                <div v-if="true">
                    <div class="description-box">从开发到优化，从管理到运维，我们的服务涵盖企业应用生命周期的每个阶段。通过新一代IT技术，
                        帮助客户实现业务流程的数字化转型。无论重塑陈旧系统，还是0-1应用开发，我们都以“精益”为原则，助企业敏捷转型。<br/>
                        我们利用DevOps持续集成/部署的方法，将研发运维一体化。从传统的被动发现到主动预警，采取及时应对措施和容灾方案，实现运
                        维工作自动化与智能化。</div>
                </div>
            </el-collapse-transition>
        </div>
        <div class="mobile-head-catalogue" style="margin-top:5.7rem">
            <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>技术能力</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>项目实施方法论</span></div>
            <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>快速交付平台</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
            <img class="ware-img" src="../../assets/image/technicalService/04-3-1技术能力.png"/>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
            <div class="mobile-content-box2">
                <img src="../../assets/image/mobile/项目实施方法论.svg"/>
                <div class="box2-right">
                    <div class="title1" style="margin-top:2.2rem">需求调研</div>
                    <div class="title2" style="margin-top:1rem">产出需求说明书并签字确认</div>
                    <div class="title1" style="margin-top:0rem">蓝图设计</div>
                    <div class="title2" style="margin-top:1rem">包括功能设计，流程设计，权限设计，视觉设计，技术设计等</div>
                    <div class="title1" style="margin-top:1.9rem">系统实现</div>
                    <div class="title2" style="margin-top:1rem">程序开发，单元测试，迭代式系统演示</div>
                    <div class="title1" style="margin-top:-3rem">集成测试</div>
                    <div class="title2" style="margin-top:1rem">测试用例，修复完善</div>
                    <div class="title1" style="margin-top:-1rem">最终功能确认</div>
                    <div class="title2" style="margin-top:1rem">针对提出的必要的优化建议，微调系统进行完善。经过最后的
                        打磨和多轮测试交付给UAT。</div>
                    <div class="title1" style="margin-top:3rem">关键用户培训</div>
                    <div class="title2" style="margin-top:1rem">产出操作手册，培训录屏及其他支持材料</div>
                    <div class="title1" style="margin-top:-3rem">UAT测试</div>
                    <div class="title2" style="margin-top:1rem">测试验收</div>
                    <div class="title1" style="margin-top:-3rem">部署上线</div>
                    <div class="title2" style="margin-top:1rem">期初数据收集和迁移，人员/角色/权限的配置，系统参数配置等上线准备工作</div>
                    <div class="title1" style="margin-top:1.9rem">上线支持</div>
                    <div class="title2" style="margin-top:1rem">收集上线后的问题，修复缺陷</div>
                </div>
            </div>
            <div class="servie-catalogue" style="margin-top:5.7rem">
                <div :class="{'title1':true,'service-ischecked':serviceIndex==1} " @click="(serviceIndex=1)"><span>卓越运维</span></div>
                <div :class="{'title1':true,'service-ischecked':serviceIndex==2} " @click="(serviceIndex=2)"><span>基于系统的持续运维</span></div>
                <div :class="{'title1':true,'service-ischecked':serviceIndex==3} " @click="(serviceIndex=3)"><span>服务模型</span></div>
            </div>
            <div v-if="(serviceIndex==1)">
                <div class="ware-content1" style="margin-top:3rem;margin-bottom: 3rem;">对于建设0-1的项目，早期阶段在需求和技术等方面会存在诸多不确定性。对于项目来说，其
                    最大的浪费是交付没用（不能解决用户问题或带来业务成功）的内容。那么我们把价值的探索和发现融入项目的交付过程中。通过以下四个阶段，逐步
                    收敛双方在需求认识上的差异，了解真正的业务需求。实现在巩固共识的前提下，再一同前行的目标。
                </div>
 
                <el-carousel indicator-position="outside" arrow="never" trigger="click" height="36rem" >
                    <el-carousel-item v-for="item in boxService" :key="item.index">
                        <div class="custom-card">
                            <div class="card-img"><img :src="item.urlImg"></div>
                            <div class="title3-1">{{item.title}}</div>
                            <div class="title3-2">{{item.content}}</div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <div style="margin-bottom:5rem"></div>
            </div>
            <div v-if="(serviceIndex==2)">
                <div class="ware-content1" style="margin-top:3rem;margin-bottom: 3rem;">对于1-N项目，我们也逐步迎来了一场以“卓越运维”为目标的变革，
                    团队的职责不再局限于系统的质保和维护，而是合并到各业务及应用的开发团队中去，全面践行DevOps的思想。通过Amazon云及一系列智能化工具和手
                    段，进一步提升运维效率和稳定性。
                </div>
 
                <el-carousel indicator-position="outside" arrow="never" trigger="click" height="36rem" >
                    <el-carousel-item v-for="item in boxService2" :key="item.index">
                        <div class="custom-card">
                            <div class="card-img"><img :src="item.urlImg"></div>
                            <div class="title3-1">{{item.title}}</div>
                            <div class="title3-2">{{item.content}}</div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <div style="margin-bottom:5rem"></div>
            </div>
            <div v-if="(serviceIndex==3)">
                <div class="ware-title" style="margin-top:4.5rem;color: #666666;">卓越运维服务价值</div>
                <img class="custom-img1" src="../../assets/image/technicalService/04-3-4卓越运维.svg"/>
                <div class="ware-title" style="margin-top:1.5rem;color: #666666;">系统运维</div>
                <img class="custom-img2" src="../../assets/image/technicalService/04-3-4系统运维.png"/>
                <div class="ware-title" style="margin-top:1.5rem;color: #666666;margin-bottom: 2rem;">运维门户平台</div>
                <el-carousel indicator-position="outside" arrow="never" trigger="click" height="27rem" >
                    <el-carousel-item v-for="item in boxService3" :key="item.index">
                        <div class="custom-card2">
                            <img :src="item.urlImg">
                            <div class="title-box">
                               <div class="card-title3-1">{{item.title}}</div>
                               <div class="card-title3-2">{{item.content}}</div> 
                            </div>     
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <div style="margin-bottom:5rem"></div>
            </div>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1">
            <div class="ware-content1" style="margin-top:3rem;margin-bottom: 3rem;line-height: 5rem;">大型传统企业往往已完成企业信息化建设，但是对于新模式、新业务的探索仍需要大量的信息技术
                    支持，而这些是大部分现有信息化系统无法提供的。SaaS服务，可定制化程度太低，无法满足企业创新业务。而定制开发，则试错成本太高。<br/>
                    我们的快速交付平台旨在帮助大型传统企业克服数字化转型过程中面临的各种挑战，可以简单，快速，高效地开发出企业级的应用系统。
                </div>
 
                <el-carousel indicator-position="outside" arrow="never" trigger="click" height="36rem" >
                    <el-carousel-item v-for="item in boxService4" :key="item.index">
                        <div class="custom-card">
                            <div class="card-img"><img :src="item.urlImg"></div>
                            <div class="title3-1">{{item.title}}</div>
                            <div class="title3-2">{{item.content}}</div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <div class="read-more" @click="jumpMobileRoute('/mobile-service-delivery')">了解详情</div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            serviceIndex:1,
            showDescription:true,
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
            boxService:[
                {
                    index:0,
                    urlImg:require("../../assets/image/technicalService/04-3-1需求调研.svg"),
                    title:'需求调研  原型设计',
                    content:'通过封闭式3天集中沟通，头脑风暴，形成图文并茂的需求文档。',
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/technicalService/04-3-1蓝图设计.svg"),
                    title:'蓝图设计',
                    content:'蓝图设计包括功能设计,流程设计,权限设计，视觉设计，技术设计等。在需求关键点上持续沟涯和确认。',
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/technicalService/04-3-1系统演示.svg"),
                    title:'系统演示',
                    content:'在每个迭代开发结束后,通过操作演示,从平面上升到立体层面，与用户沟通和再次确认需求。',
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/technicalService/04-3-1功能确认.svg"),
                    title:'最终功能确认',
                    content:'在这个阶段，通常不再收集新的需求，重点在于查漏补缺。通过关键用户上手操作体验。对项目范围内所有功能进行最后一次确认和固化。针对提出的必要的优化建议，微调系统进行完善。',
                },
            ],
            boxService2:[
                {
                    index:0,
                    urlImg:require("../../assets/image/technicalService/04-3-2运维效率.svg"),
                    title:'运维效率',
                    content:'通过有效地监控，应对各类突发故障。事前提前采取有效措施；事中提升响应速度；事后反馈，总结和优化，保障企业业务的稳定运行。',
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/technicalService/04-3-2运维安全.svg"),
                    title:'运维安全',
                    content:'运维安全事件（系统宕机、数据异常、信息丢失等）可能给企业带来致命的打击，甚至关乎业务的生死存亡。因此，防范和杜绝高危运维故障是我们一直不懈追求的目标。',
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/technicalService/04-3-1系统演示.svg"),
                    title:'业务持续性',
                    content:'客户业务发展瞬息万变，特别是重要业务，迭代变更的速度非常快。在Amazon云技术的支撑下，我们有能力持续高效地对应用进行发布、部署、变配等运维变更，保障业务的连续性和快速迭代。',
                },
            ],
            boxService3:[
                {
                    index:0,
                    urlImg:require("../../assets/image/technicalService/04-3-4跟踪记录.svg"),
                    title:'跟踪记录',
                    content:'跟踪管理即对问题的整个生命周期进行记录和管理。',
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/technicalService/04-3-4运维管理.svg"),
                    title:'运维管理',
                    content:'支持运维管理致捷或传统的开发项目。',
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/technicalService/04-3-4协同合作.svg"),
                    title:'协同合作',
                    content:'支持各个团那队之间的协同合作,保证各个支持环节的透明性。',
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/technicalService/04-3-4数据分析.svg"),
                    title:'数据分析',
                    content:'结合数据分析工具，做到对运维工作中的难点痛点了如指掌。',
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/technicalService/04-3-4管理面板.svg"),
                    title:'管理面板',
                    content:'提供管理面板，为持续不断优化管理运维工作捉供数据支撑。',
                },
            ],
            boxService4:[
                {
                    index:0,
                    urlImg:require("../../assets/image/technicalService/04-3-1简单.svg"),
                    title:'简单',
                    content:'省却编程中大量重复机械代码，让开发更多关注业务逻辑，通过代码生成，结合手动调整，实现半智能开发',
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/technicalService/04-3-1快速.svg"),
                    title:'快速',
                    content:'生成统一规范的界面及数据模型，可节省大量的工作量，快速提高开发效率',
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/technicalService/04-3-1高效.svg"),
                    title:'高效',
                    content:'统一规范、统一设计思路，快速开发出高效高质量代码，缩短项目开发周期',
                },
            ],
        };
    },

    mounted() {
        
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 2.8rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 70%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 25rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
}
.mobile-content-box2{
    width: 100%;
    display: flex;
    img{
        width: 6.7rem;
        height: 107.7rem;
        margin-top: 3rem;
    }
    .box2-right{
        margin-left: 3.7rem;
        .title1{
            height: 3.1rem;
            font-size: 2.8rem;
            font-family:'CN_Normal';
            font-weight: 400;
            color: #11A84F;
            line-height: 4.2rem;
        }
        .title2{
            max-width: 56.1rem;
            height: 9rem;
            font-size: 2.8rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #4F4F4F;
            line-height: 4rem;
        }
    }
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    height: 10rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem; 
        margin: auto; 
        align-items: center;
    }
}
.ware-img{
    width: 66.5rem;
    height: auto;
    margin-bottom: 5rem;
}
.ware-img2{
    width: 66.5rem;
    height: 28rem;
}
.service-img1{
    width: 65rem;
    height: auto;
    margin: auto;
    margin-top: 3rem;
    display: flex;
    text-align: center;
}
.servie-catalogue{
    display: flex;
    height: 6rem;
    width: 100%;
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    .title1{
        background: #EDEDED;
        border-radius: 3.1rem;
        display: flex;
        /*行元素默认不折行，设置为折行*/
        flex-wrap: wrap;
        justify-content: space-between;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #333333;
            line-height: 4.2rem;
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            display: flex;
            align-items: center;
        }
    }
    .service-ischecked{
        background: rgba(17,168,79,0.1);
        span{color: #11A84F;}
    }
    
}
.custom-card{
    width: 45.6rem;
    height: 35rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 2rem 0px rgba(236,236,236,0.6);
    padding-bottom: 0rem;
    margin: auto;
    .card-img{
        padding-top: 4.8rem;
       img{
        width: 6.4rem;
        height: 6.4rem;
        display: flex;
        margin: auto;
    } 
    }
    
    .title3-1{
        font-size: 2.8rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #000000;
        line-height: 4rem;
        text-align: center;

        margin-top: 2rem;
    }
    .title3-2{
        width: 39.6rem;
        height: 7.5rem;
        font-size: 2.2rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #4F4F4F;
        line-height: 3rem;
        text-align: justify;
        margin: auto;

        margin-top: 0.5rem;

    }
}
.custom-card2{
    width: 98%;
    height: 22.5rem;
    background: #FFFFFF;
    box-shadow: 0px 0px 2rem 0px rgba(236,236,236,0.6);
    margin: auto;
    display: flex;
    margin-top: 2rem;

    img{
        width: 12rem;
        height: 12rem;
        margin: auto;
        // margin-left: 3rem;
    } 
    .title-box{
        padding-right: 8rem;
        .card-title3-1{
            height: 2rem;
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #000000;
            line-height: 5rem;
            text-align: justify;

            margin-top: 5rem;
        }
        .card-title3-2{
            width: 39.6rem;
            height: 7.5rem;
            font-size: 2.2rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #4F4F4F;
            line-height: 3rem;
            text-align: justify;
            margin: auto;

            margin-top: 3rem;
        }
    }
}
.custom-img1{
    width: 100%;
    height: 16.6rem;

    margin-top: 1rem;
}
.custom-img2{
    width: 67.8rem;
    height: 15.5rem;
    display: flex;
    text-align: center;
    margin-top: 2rem;
}
.read-more{
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #11A84F;
    line-height: 3rem;
    text-align: center;
    margin-bottom:5rem;
    margin-top: 3rem;
}
</style>